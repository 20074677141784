<template>
    <div>
        <div v-if="showHint" class="hint-info">
            <em>每月5号前统计上月佣金，15号前系统完成转账操作。</em>
            <i @click="showHint = false"/>
        </div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    v-if="list.length > 0 || loading"
                    @load="getList"
            >
                <ul class="billing-wrap">
                    <li v-for="item in list" :key="item.id">
                        <strong class="border-btm">{{item.month }}</strong>
                        <em>结算周期：{{item.startDate}}至{{item.endDate}}</em>
                        <em>订单总额：{{item.orderTotalAmountStr}}</em>
                        <em>订单数量：{{item.orderCount}}</em>
                        <em>是否转账：{{item.status === 1 ? '是' : '否'}}</em>
                        <em>转账日期：{{item.cashDate || '--'}}</em>
                        <span>收款账号：{{item.cashAccount || '--'}}</span>
                        <span>转账流水号：{{item.cashCode || '--'}}</span>
                    </li>
                </ul>
            </van-list>
            <van-empty v-else image="error" description="暂无结算信息" />
        </van-pull-refresh>
    </div>
</template>

<script>
    import { billing } from "../../assets/api/commission";

    export default {
        data() {
            return {
                showHint: true,
                loading: false, // 上拉加载数据状态
                finished: false, // 是否可以上拉加载
                refreshing: false, // 下拉属性状态
                list: [],
                query: { page: 1, limit: 10 }
            }
        },
        created() {
            this.getList()
        },
        methods: {
            // 下拉刷新
            onRefresh() {
                // 清空列表数据
                this.finished = false;
                this.refreshing = true;
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.query.page = 1
                this.getList();
            },
            // 请求列表
            getList() {
                this.loading = true;
                billing(this.query).then(res => {
                    if (this.query.page == 1) {
                        this.list = res.data.list
                    } else {
                        this.list = [...this.list, ...res.data.list];
                    }
                    if (this.list.length >= res.data.count) {
                        this.finished = true;
                    }
                    this.refreshing = false;
                    this.query.page++;
                    this.loading = false;
                }).catch(() => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .hint-info {
        height: 76px;
        background-color: #ffe4dc;
        color: @color-fe5e30;
        font-size: @font-size1;
        margin-top: 16px;
        .main-plr;
        .flex;
        .justify-content-space-between;
        .align-items-center;

        i {
            display: block;
            width: 34px;
            height: 34px;
            background: url("../../assets/img/icon_close.png") center no-repeat;
            background-size: 100% 100%;
        }
    }

    .billing-wrap {

        li {
            background: #fff;
            .main-plr;
            padding-bottom: 22px;
            margin-top: 16px;
        }

        strong, em, span {
            display: block;
            margin-bottom: 16px;
        }

        strong {
            height: 92px;
            .flex;
            .align-items-center;
            margin-bottom: 24px;
            font-size: @font-size4;
            color: @color-191b21;
        }

        em {
            color: @color-191b21;
            font-size: @font-size2;
        }

        span {
            color: @color-878787;
            font-size: @font-size2;
        }
    }
</style>